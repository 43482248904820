:root {
  --primary: hsl(228, 57%, 41%);
  --secondary: hsla(0, 0%, 100%, 0.78);
  --primary-text: hsl(228, 57%, 28%);
  --secondary-text: hsl(0, 0%, 46%);
  --black: hsl(0, 9%, 7%);
  --blue-gradient: linear-gradient(
    97.05deg,
    hsl(228, 100%, 59%) 3.76%,
    hsl(228, 66%, 47%) 100%
  );
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: hsl(228, 100%, 59%);
  --lightBlue: hsl(228, 100%, 97%);
  --shadow: 0px 23px 21px -8px hsla(228, 100%, 77%, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  /* Hide scrollbar for Edge and Firefox */
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}
img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.disable-body-click {
  pointer-events: none;
}

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: var(--primary-text);
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: var(--secondary-text);
  font-size: 0.9rem;
}
.orangeText {
  color: hsl(39, 100%, 50%);
  font-size: 1.5rem;
  font-weight: 600;
}

.button {
  padding: 0.6rem 1.4rem;
  font-weight: 500;
  color: white;
  background: var(--blue-gradient);
  border-radius: 4px;
  transition: all 250ms ease-in;
}
.button:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.page-layout {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.loader {
  height: 5rem;
  width: 5rem;

  margin: auto;

  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* media queries */
@media (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}
