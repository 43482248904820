.inner-container {
  gap: 1.5rem;
  padding: 2rem;
  text-align: center;
  border: 6px solid #5d77d6;
  border-radius: 10px;
  background: #4161df;
}

.inner-container .primaryText {
  color: white;
  font-weight: 600;
}

.inner-container .secondaryText {
  color: rgba(255, 255, 255, 0.5);
}

.inner-container .button {
  border: 2px solid white;
  border-radius: 10px;
  box-shadow: var(--shadow);
  background: #5a73d7;
}
