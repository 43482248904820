.r-container {
  overflow: hidden;
}

.r-head {
  position: relative;
  margin-bottom: 2rem;
}

.swiper-horizontal {
  overflow: visible;
}

.r-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  gap: 1rem;
}

.r-buttons button {
  padding: 0.2rem 0.8rem;
  border-radius: 5px;
  color: blue;
  background-color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.r-buttons > :nth-child(1) {
  background-color: #eeeeff;
}

.r-buttons > :nth-child(2) {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
}

@media (max-width: 767px) {
  .r-head {
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .r-buttons {
    position: initial;
    margin-top: 1rem;
  }
}
