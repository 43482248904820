.header-wrapper {
  color: white;
  background: var(--black);
  overflow: hidden;
}

.header-container {
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--secondary);
}

.logo-wrap__header {
  width: 100px;
}

.header-menu {
  column-gap: 2rem;
}

.header-menu > *:hover {
  cursor: pointer;
}

.header-menu.active-pointer {
  pointer-events: auto;
}

.menu-icon {
  display: none;
}

.header-menu.active-pointer {
  pointer-events: auto;
}

@media (width < 768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .header-menu {
    z-index: 10;
    position: absolute;
    top: 3rem;
    right: 3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 3rem;
    border-radius: 8px;
    font-weight: 500;
    color: var(--black);
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
  }
}
