.properties-container {
  gap: 2rem;
}

.properties-container .search-bar {
  width: clamp(12rem, 100%, 28rem);
  flex-wrap: nowrap;
  border: px solid rgba(120, 120, 120, 0.37);
  border-radius: 30px;
}

.properties-container .search-bar .button {
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 12px;
}

.properties-container .search-bar input {
  width: 70%;
}
