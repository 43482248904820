.property-container {
  position: relative;
  gap: 2rem;
}

.property-container .like {
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
}

.property-container > img {
  max-height: 35rem;
  align-self: center;
  border-radius: 1rem;
}

.property-details {
  width: 100%;
  gap: 2rem;
  justify-content: space-between;
}

.property-details > div {
  flex: 1;
  gap: 1.5rem;
}

.property-details .head {
  width: 100%;
  justify-content: space-between;
  gap: 0.2rem;
}

.property-details .head > p {
  font-size: 2rem;
}

.property-details .facilities {
  gap: 1rem;
  padding: 1rem;
  font-size: 0.9rem;
}

.property-details .facility {
  gap: 0.5rem;
}

.property-details .desc {
  text-align: justify;
}

.property-details .location {
  gap: 1rem;
}

.property-details .location span:first-child {
  margin-right: 0.5rem;
}

.property-details .button {
  width: 100%;
  margin-top: 1rem;
}

@media (width < 768px) {
  .property-container > div {
    flex-direction: column;
  }

  .property-details > div {
    width: 100%;
  }

  .property-details .head {
    flex-direction: column;
  }

  .property-details .facilities {
    flex-direction: column;
    align-items: flex-start;
  }
}
