.c-container {
  justify-content: space-between;
}

.c-container > div {
  flex: 1;
}

.c-first {
  align-items: center;
  gap: 0.5rem;
}

.c-second {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contactModes {
  margin-top: 2rem;
  gap: 1rem;
}

.contactModes .row {
  gap: 1.5rem;
}

.mode {
  width: 16rem;
  gap: 1rem;
  padding: 1rem;
  border: 0.8px solid rgba(128, 128, 128, 0.2);
  border-radius: 5px;
  transition: all 300ms ease-in;
}

.mode button {
  width: 100%;
  background: var(--lightBlue);
  color: var(--blue);
  font-size: 0.9rem;
  font-weight: 600;
}

.mode > :nth-child(1) {
  width: 100%;
  gap: 1.6rem;
}

.mode .primaryText {
  font-size: 1.1rem;
  font-weight: 600;
}

.mode:hover {
  scale: 1.05;
  box-shadow: var(--shadow);
}

.mode .button:hover {
  scale: 0.9;
  background: var(--blue-gradient);
  color: white;
}

@media (max-width: 767px) {
  .c-container {
    flex-direction: column;
  }
  .contactModes {
    width: 100%;
  }

  .row {
    width: 100%;
    flex-direction: column;
  }

  .mode {
    width: 100%;
  }
}
