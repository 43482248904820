.p-card {
  z-index: 0;
  position: relative;
  max-width: max-content;
  gap: 0.6rem;
  margin: auto;
  padding: 1rem;
  border-radius: 10px;
  transition: all 200ms ease-in;
}
.p-card:hover {
  /* scale: 1.02; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(136, 160, 255, 0.45) 218%
  );
  box-shadow: 0 32px 19px -21px rgba(136, 160, 255, 0.2);
  cursor: pointer;
}

.p-card > svg {
  z-index: 1;
  position: absolute;
  top: 25px;
  right: 30px;
}

.p-card > img {
  width: 15rem;
  height: 10rem;
  border-radius: 8px;
}

.p-card__price {
  display: flex;
  gap: 2px;
  font-size: 1.2rem;
  font-weight: 600;
}

.p-card__price span {
  color: orange;
}

.p-card__name {
  font-size: 1.3rem;
}

.p-card__detail {
  width: 15rem;
  font-size: 0.7rem;
}
